/* eslint-disable jsx-a11y/control-has-associated-label */
import "react-image-lightbox/style.css"

import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { LinkedinShareButton, EmailShareButton } from "react-share"
import Lightbox from "react-image-lightbox"

const SinglePost = ({ pageContext }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  // const gallery = pageContext?.data?.postAcf?.gallery

  const gallery = pageContext?.data?.postAcf?.gallery
    ? pageContext?.data?.postAcf?.gallery.map(item => {
        return item.localFile.publicURL
      })
    : null

  const lang = pageContext.data.language.slug

  return (
    <Layout language={pageContext.data.language} lang={lang} isNews={true}>
      <SEO
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo.metaDesc}
      />
      <section className="single-post">
        <div className="container">
          <div
            className="single-post__main-img"
            style={{ backgroundImage: `url(${pageContext.image})` }}
          >
            <div className="single-post__date">{pageContext.date}</div>
          </div>
          <div className="single-post__center">
            <h1 className="single-post__title">{pageContext.title}</h1>
          </div>
          <div
            className="single-post__content"
            dangerouslySetInnerHTML={{ __html: pageContext.content }}
          />
          {gallery && (
            <div className="single-post__gallery">
              {gallery?.map((item, index) => (
                <button
                  onClick={e => {
                    e.preventDefault()
                    setIsLightboxOpen(true)
                    setPhotoIndex(index)
                  }}
                  key={index}
                  style={{ backgroundImage: `url('${item}')` }}
                ></button>
              ))}
            </div>
          )}
        </div>
      </section>
      <div className="container">
        <div className="single-post__bottom-row">
          <Link to="/news" className="single-post__link">
            All blog articles
          </Link>
          <div className="single-post__socials">
            <LinkedinShareButton
              url={pageContext.uri}
              className="single-post__single-social-link"
            >
              <img src={require("../assets/images/linkedin-ico.svg")} alt="" />
            </LinkedinShareButton>
            <div className="single-post__divider"></div>
            <EmailShareButton
              url={pageContext.uri}
              className="single-post__single-social-link"
              subject={pageContext.title}
            >
              <img src={require("../assets/images/email-ico.svg")} alt="" />
            </EmailShareButton>
          </div>
        </div>
      </div>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </Layout>
  )
}

export default SinglePost
